import MetricCard from "./graphs/MetricCard";
import ClientsTable from "./graphs/ClientsTable";
import RevenueChart from "./graphs/RevenueChart";
import ModulesChart from "./graphs/ModulesChart";
import "../../styles/superAdmin/dashboard.css";
import { metrics } from "./dummydata/metricData";

export default function Dashboard() {

  return (
    <div className="dashboard-container">
      <h2 className="welcome-message my-5" style={{color : "#16166C"}} id="negative-margin">
        Good Morning, Super admin!{" "}
        <span role="img" aria-label="wave">
          👋🏼
        </span>
      </h2>

      <div className="row g-4 mb-4">
        {metrics.map((metric, index) => (
          <div key={index} className="col-12 col-sm-6 col-xl-3">
            <MetricCard {...metric} />
          </div>
        ))}
      </div>

      <div className="row g-4 mb-4">
        <div className="col-12 col-lg-5">
          <ClientsTable />
        </div>
        <div className="col-12 col-lg-7">
          <RevenueChart />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <ModulesChart />
        </div>
      </div>
    </div>
  );
}
