import { useSelector, useDispatch } from "react-redux";
import {
  makeExtendPerModuleTrue,
  makeExtendPerModuleFalse,
  makeExtendAllModeFalse,
  makeExtendAllModeTrue,
  makeExtendModeFalse,
} from "../../Admin/adminActions";
import validator from "validator";

export const useModuleState = (index) => {
  const dispatch = useDispatch();

  const isPerModule = useSelector(
    (state) => state.admin.extendMode?.perModule[index] || false
  );

  const isAllModule = useSelector(
    (state) => state.admin.extendMode?.allModule[index] || false
  );

  const isExtendModeOpen = useSelector(
    (state) => state.admin.extendMode[index] || false
  );

  const perModuleID = useSelector(
    (state) => state.admin.extendMode?.singleModuleMode?.id
  );

  const toggleModuleMode = () => {
    if (isAllModule) {
      dispatch(makeExtendAllModeFalse(index));
      dispatch(makeExtendPerModuleTrue(index));
    } else {
      dispatch(makeExtendPerModuleFalse(index));
      dispatch(makeExtendAllModeTrue(index));
    }
  };

  const closeExtendMode = () => {
    dispatch(makeExtendModeFalse(index));
  };

  return {
    perModuleID,
    isPerModule,
    isAllModule,
    isExtendModeOpen,
    toggleModuleMode,
    closeExtendMode,
  };
};

export const useLoginValidator = (values) => {
  let valid = true;
  const errorMessages = [];

  const username = values.userName;
  const password = values.passWord;

  if (!username || validator.isEmpty(username) || username.length < 3) {
    valid = false;
    errorMessages.push("Username must contain minimum of 3 characters.");
  }

  if (!password || password.length < 6) {
    valid = false;
    errorMessages.push("Password must be at least 6 characters long.");
  }

  return {
    isValid: valid,
    errorMessages: errorMessages,
  };
};

export const useDetectMobileView = () => {
  const isMobile = useSelector((state) => state.admin.mobileView);
  return isMobile;
};
