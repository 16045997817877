import React, { useEffect } from "react";
import {
  availableModules,
  subscribedModules,
} from "../../Admin/dummydata/modules";
import { Input, InputNumber } from "antd";
import {
  updateCompanyDetails,
  initializeEditCompanyForm,
  makeAddModeTrue,
  makeEditModeFalse,
  makeAddModeFalse,
} from "../../../redux/Admin/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const { Search } = Input;

export default function ClientDetails({
  companyDetail,
  isEdit = false,
  addNewDetails = false,
}) {
  const dispatch = useDispatch();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("add-client")) {
      dispatch(makeAddModeTrue());
      dispatch(makeEditModeFalse());
    }

    return () => {
      dispatch(makeAddModeFalse());
      dispatch(makeEditModeFalse());
    };
  }, [dispatch, location.pathname]);

  const editCompany = useSelector((state) => state.admin.editCompany);
  const sitePrefix = companyDetail?.site && companyDetail?.site.split(".")[0];

  const moduleNamesString = subscribedModules
    .map((module) => module.name)
    .join(", ");
  // Initialize form data when component mounts or companyDetail changes
  useEffect(() => {
   console.log(companyDetail, "at form");
   

    if (isEdit && companyDetail && !addNewDetails) {
      dispatch(
        initializeEditCompanyForm({
          name: companyDetail?.name || "",
          email: companyDetail?.email || "",
          contact: companyDetail?.phone || "",
          site: sitePrefix || "",
          users: companyDetail?.users || "",
          subscribedModules:
            moduleNamesString && moduleNamesString.length > 0
              ? moduleNamesString.split(", ")
              : [],
        })
      );
    }

    return () => {
      dispatch(
        initializeEditCompanyForm({
          name: "",
          email: "",
          contact: "",
          site: "",
          users: "",
          subscribedModules: [],
        })
      );
    };
  }, [
    companyDetail,
    isEdit,
    moduleNamesString,
    dispatch,
    sitePrefix,
    addNewDetails,
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateCompanyDetails(name, value));
  };

  const handleModuleToggle = (moduleName) => {
    const isSubscribed = editCompany.subscribedModules?.includes(moduleName);
    // Toggling the checkbox for subscribing modules in Redux
    dispatch(
      updateCompanyDetails(
        "subscribedModules",
        isSubscribed
          ? editCompany.subscribedModules.filter((name) => name !== moduleName)
          : [...editCompany.subscribedModules, moduleName]
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(editCompany);
  };

  console.log(companyDetail);
  const renderViewMode = () => (
    <form>
      <div className="info-flex">
        <div className="info-section">
          <div className="info-group">
            <h1 className="info-label">Company Name:</h1>
            <span className="info-value">{companyDetail?.name}</span>
          </div>
          <div className="info-group">
            <h1 className="info-label">Company Site:</h1>
            <span className="info-value">{companyDetail?.site}</span>
          </div>
        </div>

        <div className="info-section">
          <div className="info-group">
            <h1 className="info-label">Company Email:</h1>
            <span className="info-value">{companyDetail?.email}</span>
          </div>
          <div className="info-group">
            <h1 className="info-label">Number of users:</h1>
            <span className="info-value">{companyDetail?.users}</span>
          </div>
        </div>

        <div className="info-section">
          <div className="info-group">
            <h1 className="info-label">Company Contact:</h1>
            <span className="info-value">{companyDetail?.phone}</span>
          </div>
          <div className="info-group">
            <h1 className="info-label">Subscribed Modules:</h1>
            <span className="info-value">
              {companyDetail?.subscribedModules}
            </span>
          </div>
        </div>
      </div>

      <h2>Available Modules</h2>
      <div className="modules-flex">
        {availableModules.map((module) => (
          <div key={module.id} className="module-card">
            <h4>{module.name}</h4>
            <div className="expiry-date">Expiry date: {module.expiryDate}</div>
          </div>
        ))}
      </div>
    </form>
  );

  const renderEditMode = () => (
    <form onSubmit={handleSubmit}>
      <div className="info-flex">
        <div className="info-section">
          <div className="info-group">
            <h1 className="info-label">Company Name:</h1>
            <Input
              name="name"
              value={editCompany?.name}
              onChange={handleInputChange}
              placeholder="Enter company name"
              size="large"
            />
          </div>
          <div className="info-group">
            <h1 className="info-label">Company Site:</h1>
            <div className="site-input-wrapper">
              <Search
                placeholder="Enter site name"
                name="site"
                value={editCompany?.site}
                enterButton=".apps.sslinsight.com"
                size="large"
              />
            </div>
          </div>
        </div>

        <div className="info-section">
          <div className="info-group">
            <h1 className="info-label">Company Email:</h1>
            <Input
              name="email"
              type="email"
              value={editCompany?.email}
              onChange={handleInputChange}
              placeholder="Enter company email"
              size="large"
            />
          </div>
          <div className="info-group">
            <h1 className="info-label">Number of users:</h1>
            <InputNumber
              id="number-input"
              name="users"
              type="number"
              min="1"
              value={editCompany?.users}
              onChange={handleInputChange}
              size="large"
            />
          </div>
        </div>

        <div className="info-section">
          <div className="info-group">
            <h1 className="info-label">Company Contact:</h1>
            <Input
              name="contact"
              value={editCompany?.contact}
              onChange={handleInputChange}
              size="large"
              placeholder="Enter contact number"
            />
          </div>
        </div>
      </div>

      <h2>Subscription Details</h2>
      <div className="modules-flex">
        {availableModules.map((module) => (
          <div key={module.id} className="module-card module-card-border">
            <div className="module-checkbox-wrapper">
              <input
                type="checkbox"
                id={module.id}
                checked={editCompany?.subscribedModules?.includes(module.name)}
                onChange={() => handleModuleToggle(module.name)}
              />
              <label htmlFor={module.id} className="module-label">
                {module.name}
              </label>
            </div>
          </div>
        ))}
      </div>

      <div className="submit-wrapper">
        <button type="submit" className="submit-button">
          Update
        </button>
      </div>
    </form>
  );

  console.log(addNewDetails, "clientDetails");
  return (
    <div className="company-details">
      <div className="details-container">
        <h2>Company Details</h2>
        {isEdit || addNewDetails ? renderEditMode() : renderViewMode()}
      </div>
    </div>
  );
}
