import React from "react";
import { Button } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

export default function BackButton({ backFn }) {
  return (
    <div>
      <Button
        size="small"
        className="back-button d-flex align-items-center justify-content-center mb-2"
        icon={<ArrowLeftOutlined className="me-2" />}
        onClick={backFn}
      >
        Back
      </Button>
    </div>
  );
}
