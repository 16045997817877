import { combineReducers } from "redux";
import moduleReducer from "./modules/modulesReducer";
import userReducer from "./users/userReducer";
import { adminReducer } from "./Admin/adminReducer";

const rootReducer = combineReducers({
  modules: moduleReducer,
  user: userReducer,
  admin: adminReducer,
});

export default rootReducer;
