import { useModuleState } from "../../../redux/hooks/superAdminCustomHooks/hooks";
import { DashOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Modal, Button, DatePicker } from "antd";
import {
  makeExtendPerModuleIDFalse,
  makeExtendPerModuleIDTrue,
  openModal,
} from "../../../redux/Admin/adminActions";
import { useDispatch } from "react-redux";
import {
  makeAddModeFalse,
  makeEditModeFalse,
  closeModal,
} from "../../../redux/Admin/adminActions";
import { availableModules } from "../../Admin/dummydata/modules";
import React from "react";

export default function TableleActionColumn({
  record,
  handleViewDetails,
  handleEditBtnClick,
  handleExtendSubscriptionClick,
  isModalOpen,
}) {
  const index = Number(record.key);
  const {
    isPerModule,
    // isAllModule,
    isExtendModeOpen,
    toggleModuleMode,
    closeExtendMode,
    isPerModuleIDModal,
    perModuleID,
  } = useModuleState(index);

  const dispatch = useDispatch();

  const handleModalOpen = (e) => {
    e.stopPropagation();
    dispatch(openModal(index));
    dispatch(makeAddModeFalse());
  };

  const handleModalClose = (e) => {
    e.stopPropagation();
    dispatch(closeModal(index));
    dispatch(makeEditModeFalse());
  };

  const handlePerModuleClick = (index, id) => {
    dispatch(makeExtendPerModuleIDTrue(index, id));
  };

  const handlePerModuleClose = (index) => {
    setSelectedDate(null);
    dispatch(makeExtendPerModuleIDFalse(index));
  };

  const [selectedDate, setSelectedDate] = React.useState(null);
  const onChange = (date, dateString) => {
    console.log(date, dateString);
    setSelectedDate(dateString);
  };

  const handleUpdate = (moduleId, moduleName) => {
    if (selectedDate) {
      const companyDetails = record;
      console.log("Updating:", {
        moduleId,
        moduleName,
        date: selectedDate,
        companyDetail: companyDetails,
      });

      handlePerModuleClose(index);
    }
  };

  const handleUpdate1 = () => {
    if (selectedDate) {
      const companyDetails = record;
      console.log("Updating:", {
        date: selectedDate,
        companyDetail: companyDetails,
      });

      handlePerModuleClose(index);
    }
  };

  return (
    <div className="position-relative action" onClick={handleModalOpen}>
      <DashOutlined style={{ cursor: "pointer", color: "#16166C" }} />
      <div className="extended-modal-wrapper">
        <Modal
          title={
            <div className="d-flex justify-content-between align-items-center">
              <span>Extend Module Subscription</span>
              <Button
                size="middle"
                className="add-client-button"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleModuleMode();
                }}
              >
                {isPerModule ? "Extend per module" : "Extend all modules"}
              </Button>
            </div>
          }
          open={isExtendModeOpen}
          closable={false}
          onCancel={closeExtendMode}
          footer={null}
          wrapClassName="extended-modal"
          width={800}
        >
          <div className="module-cards-container1">
            {isPerModule ? (
              availableModules.map((module, index) => (
                <>
                  <div key={module.id} className="module-card1">
                    <div className="module-card-content1">
                      <span className="module-card1-text">{module.name}</span>
                      <PlusCircleFilled
                        style={{
                          color: "#16166C",
                          fontSize: "20px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePerModuleClick(index, module.id);
                        }}
                      />
                    </div>
                  </div>

                  {console.log(isPerModuleIDModal, "up")}
                  {console.log(perModuleID, "up1")}
                  {/* open={isPerModuleIDModal} */}
                  <Modal
                    open={perModuleID - 1 === index} // I nearly died finding this fix
                    footer={null}
                    closable={false}
                    wrapClassName="extended-modal1"
                    onCancel={() => handlePerModuleClose(index)}
                  >
                    <div className="d-flex flex-column px-4 py-4 gap-3">
                      <div className="d-flex flex-column">
                        <h1 className="font-weight-bold mb-3 header">{module.name}</h1>
                        <h1>Current Subscription Timeline</h1>
                        <span>23/04/2023 - 23/04/2024</span>
                      </div>

                      <div>
                        <h1>Extend till</h1>
                        <DatePicker onChange={onChange} />
                      </div>
                      <Button
                        size="middle"
                        className="add-client-button w-25"
                        onClick={() => handleUpdate(module.id, module.name)}
                        // disabled={!selectedDate}
                      >
                        Update
                      </Button>
                    </div>
                  </Modal>
                </>
              ))
            ) : (
              <div className="d-flex flex-column gap-3">
                <div>
                  <h1>Extend all till</h1>
                  <DatePicker onChange={onChange} />
                </div>
                <Button
                  size="middle"
                  className="add-client-button w-50"
                  onClick={() => handleUpdate1()}
                >
                  Update
                </Button>
              </div>
            )}
          </div>
        </Modal>

        <Modal
          className="custom-modal"
          width={200}
          open={isModalOpen[index]}
          closable={false}
          onCancel={handleModalClose}
          footer={null}
        >
          <div className="d-flex flex-column gap-3">
            <button
              className="modal_button"
              onClick={() => handleViewDetails(index)}
            >
              View
            </button>
            <button
              className="modal_button"
              onClick={() => handleEditBtnClick(index)}
            >
              Edit
            </button>
            <button
              className="modal_button"
              onClick={() => handleExtendSubscriptionClick(index)}
            >
              Extend Subscription
            </button>
            <button className="modal_button">Cancel Subscription</button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
