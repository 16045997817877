import React, { useEffect } from "react";
import {
  toggleAdminNav,
  checkMobileView,
} from "../../../redux/Admin/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { MenuOutlined } from "@ant-design/icons";

export default function MobileHamburger() {
  const dispatch = useDispatch();
  const hamburgerState = useSelector((state) => state.admin.hamburger);
  const isMobile = useSelector((state) => state.admin.mobileView);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      dispatch(checkMobileView(width <= 767));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  const handleSidebarToggle = () => {
    dispatch(toggleAdminNav(hamburgerState));
  };
  return (
    isMobile && (
      <div className="z-index position-absolute hamburger-position">
        <MenuOutlined
          onClick={handleSidebarToggle}
          className="d-flex justify-content-center align-items-center mobile-hamburger"
          style={{ color: "black", fontSize: "30px" }}
        />
      </div>
    )
  );
}
