import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useDetectMobileView } from "../../../redux/hooks/superAdminCustomHooks/hooks";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const shortLabels = [
  "HRIS",
  "Leave",
  "C&B",
  "Performance",
  "Requests",
  "Survey",
  "Recruitment",
  "Incident",
  "Shift",
  "Competency",
  "LMS",
  "Training",
];

// responsive issues with this
const fullLabels = [
  "Human Resource Information System",
  "Leave Management",
  "Compensation & Benefits",
  "Performance Management",
  "Request Management",
  "Survey Management",
  "Recruitment Management",
  "Incident Management",
  "Shift Management",
  "Competency Management",
  "Learning Management System",
  "Training Management",
].map((label) => label);

const data = {
  labels: shortLabels,
  datasets: [
    {
      data: [60, 80, 40, 65, 60, 30, 60, 80, 40, 65, 60, 30],
      backgroundColor: shortLabels.map((_, index) =>
        index % 2 === 0 ? "#FF6B6B" : "#FFC107"
      ),
    },
  ],
};

// const isMobile = useSelector((state) => state.admin.mobileView);

const ModulesChart = () => {
  const [isMobile, setIsMobile] = useState(null);

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: "Modules Users Analysis",
        font: {
          size: 14,
        },
      },
      tooltip: {
        callbacks: {
          title: (context) => context,
        },
        padding: 12,
        titleSpacing: 8,
        titleMarginBottom: 8,
        titleFont: {
          size: 13,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          maxRotation: isMobile ? 45 : 0, // will change to 45deg on mobile
          minRotation: isMobile ? 45 : 0, // will change to 45deg on mbile
          font: {
            size: 11,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          font: {
            size: 11,
          },
        },
      },
    },
  };

  const mobileView = useDetectMobileView();

  useEffect(() => {
    setIsMobile(mobileView);
  }, [mobileView]);
  return (
    <div
      className="w-100 bg-white p-4 rounded-lg shadow-sm d-flex justify-content-center align-items-center"
      style={{ maxHeight: "400px" }}
    >
      <Bar options={options} data={data} />
    </div>
  );
};

export default ModulesChart;
