import React from "react";

export default function MetricCard({ icon, title, value, change, changeType }) {
  return (
    <div className="metric-card bg-white p-4 rounded-3 shadow-sm d-flex align-items-center">
      <div className="w-100 d-flex justify-content-between align-items-center mb-3">
        <img src={icon} alt={title} width="40" height="40" />

        <div className="d-flex flex-column gap-2">
          <h4 className="mb-0">{title}</h4>
          <h6 className="mb-2">{value}</h6>
        </div>

        <span
          className={`badge ${
            changeType === "increase" ? "dash_text-success" : "dash_text-danger"
          }`}
        >
          {change}
        </span>
      </div>
    </div>
  );
}
