import { Button, Drawer, Input } from "antd";
import {
  CloseOutlined,
  OrderedListOutlined,
  UnorderedListOutlined,
  BoldOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { useRef, useState } from "react";

export default function ModuleEditModal({ open, onClose, values, index }) {
  const [tempUpload, setTempUpload] = useState(
    "/assets/superAdmin/Human resources cards.svg"
  );

  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!["image/jpeg", "image/jpg", "image/png"].includes(file.type)) {
        console.error("Please select a valid image file (JPEG, JPG, or PNG)");
        return;
      }

      const imageUrl = URL.createObjectURL(file);
      setTempUpload(imageUrl);
    }
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    console.log("hello");
  };

  return (
    <Drawer
      className="add_module_edit_modal rounded-3 z-3"
      open={open}
      onClose={() => onClose(index)}
      closable={false}
      width={window.innerWidth < 768 ? "100%" : 600}
      height={window.innerWidth < 768 ? "100%" : "auto"}
      placement="right"
    >
      <div className="modal-header">
        <div className="header-content">
          <h1 className="box-header">Edit Module</h1>
          <p>Proceed to edit this module</p>
        </div>
        <CloseOutlined className="close-icon" onClick={() => onClose(index)} />
      </div>

      <form className="input-container" onSubmit={handleModalSubmit}>
        <div className="input-group1">
          <div className="input-field">
            <label htmlFor="module_name">Name:</label>
            <Input placeholder="Billings Management" value={values.title} />
          </div>

          <div className="input-field">
            <label htmlFor="module_price">Module Price:</label>
            <Input value={values.price} />
          </div>
        </div>

        <div className="description-field">
          <label>Description</label>
          <div className="rich-text-toolbar">
            <select className="font-select">
              <option>San Serif</option>
            </select>
            <div className="formatting-buttons">
              <button className="font-weight-bolder">
                <BoldOutlined />
              </button>
              <button>
                <ItalicOutlined />
              </button>
              <button>
                <UnderlineOutlined />
              </button>
              <button>
                <LinkOutlined />
              </button>
              <button className="font-weight-bolder bold-text">
                <OrderedListOutlined className="bold-text" />
              </button>
              <button>
                <UnorderedListOutlined />
              </button>
            </div>
          </div>
          <Input.TextArea
            id="text-area"
            //   className="text-area"
            placeholder="More information about the module are to be inputed here"
            rows={4}
          />
        </div>

        <div className="upload-section">
          <h3>Upload Icon</h3>
          <p>
            Select and upload the preferred icon of your module from your media
          </p>
          <div
            className="upload-area position-relative"
            onClick={handleFileButtonClick}
          >
            <img
              src={tempUpload}
              alt="uploaded"
              width={80}
              height={80}
              className="uploaded-image position-absolute"
            />
            <img
              src="/assets/superAdmin/Upload.svg"
              alt="upload"
              width={50}
              height={50}
              className="upload-icon"
            />
            <p>Change File</p>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              accept=".jpg,.jpeg,.png"
              style={{ display: "none" }}
            />
          </div>
        </div>

        <div className="w-100 d-flex align-items-center justify-content-center">
          <Button className="add-client-button" htmlType="submit">Update</Button>
        </div>
      </form>
    </Drawer>
  );
}
