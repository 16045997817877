import "../../styles/superAdmin/addModule.css";
import { EllipsisOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  closeModal,
  openModal,
  openDrawer,
  closeDrawer,
} from "../../redux/Admin/adminActions";
import ModuleEditModal from "../UI/superAdmin/module_edit_modal";

const cardData = [
  {
    title: "Billing Management",
    price: "70,000",
    imageUrl: "/assets/superAdmin/Human resources cards.svg",
  },
  {
    title: "Inventory Management",
    price: "50,000",
    imageUrl: "/assets/superAdmin/Human resources cards.svg",
  },
  {
    title: "User Management",
    price: "30,000",
    imageUrl: "/assets/superAdmin/Human resources cards.svg",
  },
  {
    title: "Project Management",
    price: "90,000",
    imageUrl: "/assets/superAdmin/Human resources cards.svg",
  },
  {
    title: "Finance Management",
    price: "120,000",
    imageUrl: "/assets/superAdmin/Human resources cards.svg",
  },
];

export default function AddModule() {
  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.admin.modalOpen);
  const isDrawerOpen = useSelector((state) => state.admin.drawerOpen);

  const handleModalOpen = (index) => {
    dispatch(openModal(index));
  };

  const handleModalClose = (index) => {
    dispatch(closeModal(index));
  };

  const handleDrawerOpen = (index) => {
    dispatch(openDrawer(index));
  };

  const handleDrawerClose = (index) => {
    console.log(index);
    dispatch(closeDrawer(index));
  };

  return (
    <section className="container-fluid">
      <div className="module-header-container mb-4" id="negative-margin">
        <h1 className="">Add Module</h1>
        <p className="text-muted">
          Modules can be activated or deactivated here
        </p>
      </div>

      <div className="super-admin-cards-container rounded-3 p-4">
        {/* Inactive Modules Section */}
        <div className="module-container mb-4">
          <h2 className="box-header h5 font-weight-bold">Inactive Module</h2>
        </div>

        <div className="row g-3 mb-4">
          {cardData?.map((card, index) => (
            <>
              <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-3">
                <div className="super-admin-card d-flex justify-content-between p-3 rounded-3">
                  <div className="d-flex align-items-center">
                    <div className="me-3">
                      <img
                        src={card.imageUrl}
                        alt="card"
                        className="card-image"
                      />
                    </div>
                    <div>
                      <p className="mb-1">{card.title}</p>
                      <p className="mb-0">Price: {card.price}</p>
                    </div>
                  </div>
                  <div
                    className="super-admin-card-icon"
                    onClick={() => handleModalOpen(index)}
                  >
                    <EllipsisOutlined />
                  </div>

                  <div>
                    <Modal
                      className="custom-modal"
                      width={200}
                      open={isModalOpen[index]}
                      closable={false}
                      onCancel={() => handleModalClose(index)}
                      footer={null}
                    >
                      <div className="d-flex flex-column gap-3">
                        <button className="modal_button">Activate</button>
                        <button
                          className="modal_button"
                          onClick={() => handleModalOpen(index)}
                        >
                          Edit
                        </button>
                        <button className="modal_button">Delete</button>
                      </div>
                    </Modal>
                  </div>

                  <ModuleEditModal
                    open={isDrawerOpen[index]}
                    onClose={handleDrawerClose}
                    values={card}
                    index={index}
                  />
                </div>
              </div>
            </>
          ))}
        </div>

        {/* Active Modules Section */}
        <div className="module-container mb-4">
          <h2 className="box-header h5 font-weight-bold">Active Module</h2>
        </div>

        <div className="row g-3">
          {cardData.map((card, index) => (
            <div key={index} className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="super-admin-card d-flex justify-content-between p-3 rounded-3">
                <div className="d-flex align-items-center">
                  <div className="me-3">
                    <img
                      src={card.imageUrl}
                      alt="card"
                      className="card-image"
                    />
                  </div>
                  <div>
                    <p className="mb-1">{card.title}</p>
                    <p className="mb-0">Price: {card.price}</p>
                  </div>
                </div>
                <div
                  className="super-admin-card-icon"
                  onClick={() => handleModalOpen(index)}
                >
                  <EllipsisOutlined />
                </div>

                <div>
                  <Modal
                    className="custom-modal"
                    width={200}
                    open={isModalOpen[index]}
                    closable={false}
                    onCancel={() => handleModalClose(index)}
                    footer={null}
                  >
                    <div className="d-flex flex-column gap-3">
                      <button className="modal_button">Activate</button>
                      <button
                        className="modal_button"
                        onClick={() => handleDrawerOpen(index)}
                      >
                        Edit
                      </button>
                      <button className="modal_button">Delete</button>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
