import React, { useCallback, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ProfilCard from "./profileCard";
import { useDispatch, useSelector } from "react-redux";
import { toggleAdminNav } from "../../../redux/Admin/adminActions";
import { MdDashboard } from "react-icons/md";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { IoIosPerson } from "react-icons/io";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";

const SIDEBAR_MENU = [
  {
    title: "Dashboard",
    path: "dashboard",
    icon: <MdDashboard size={25} />,
  },
  {
    title: "Add Module",
    path: "add-module",
    icon: <AiOutlineCodeSandbox size={25} />,
  },
  {
    title: "Clients",
    path: "clients",
    icon: <IoIosPerson size={25} />,
  },
  {
    title: "Add Client",
    path: "add-client",
    icon: <HiOutlineBuildingOffice2 size={25} />,
  },
];

const SidebarMenuItem = ({ icon, path, title, isActive, toggleSidebar }) => {
  const navigate = useNavigate();
  const handleRoute = (pathName) => {
    navigate(`/super_admin/${pathName}`);
    if (toggleSidebar) {
      toggleSidebar();
    }
  };

  return (
    <li
      className={`rounded-3 ${isActive ? "super-admin-nav-active" : ""}`}
      onClick={() => handleRoute(path)}
    >
      <span className="me-4 d-inline-block w-auto super-admin-sidebar-icon">
        {/* <img src={icon} alt={`${title} icon`} /> */}
        {icon}
      </span>
      <Link to={path}>{title}</Link>
    </li>
  );
};

const Logo = () => (
  <div className="logo-container d-flex justify-content-center align-items-center gap-3 mb-5 my-4">
    <img
      src="/assets/superAdmin/profile.png"
      alt="profile"
      width="40"
      height="40"
      className="rounded-circle"
    />
    <h1>Aura Int. Distro</h1>
  </div>
);

export default function AdminSidebar() {
  const location = useLocation();

  const getCurrentLocation = useCallback(
    (path) => {
      const activePath = location.pathname
        .split("/")[2]
        .toLocaleLowerCase()
        .includes(path?.toLocaleLowerCase());

      console.log(activePath);
      return activePath;
    },
    [location.pathname]
  );

  useEffect(() => {
    getCurrentLocation(location.pathname.split("/")[2].toLocaleLowerCase());
  }, [getCurrentLocation, location.pathname]);

  const isMobile = useSelector((state) => state.admin.mobileView);
  const hamburger = useSelector((state) => state.admin.hamburger);

  const sidebarRef = useRef(null);
  const dispatch = useDispatch();

  const toggleSidebar = useCallback(() => {
    if (isMobile && hamburger) {
      dispatch(toggleAdminNav(hamburger));
    }
  }, [isMobile, hamburger, dispatch]);

  useEffect(() => {
    const handleSidebarToggle = (e) => {
      if (
        isMobile &&
        hamburger &&
        sidebarRef.current &&
        !sidebarRef.current.contains(e.target)
      ) {
        const hamburgerButton = document.querySelector(".mobile-hamburger");
        if (!hamburgerButton?.contains(e.target)) {
          toggleSidebar();
        }
      }
    };
    document.addEventListener("mousedown", handleSidebarToggle);

    return () => document.removeEventListener("mousedown", handleSidebarToggle);
  }, [isMobile, hamburger, dispatch, toggleSidebar]);

  return (
    <div
      className={`super-admin-sidebar ${
        isMobile && hamburger ? "open" : "closed"
      }`}
      ref={sidebarRef}
    >
      <Logo />

      <ul>
        {
          <ul>
            {SIDEBAR_MENU.map((menuItem, index) => (
              <SidebarMenuItem
                key={index}
                {...menuItem}
                isActive={getCurrentLocation(menuItem.path)}
                toggleSidebar={toggleSidebar}
              />
            ))}
          </ul>
        }
      </ul>

      {isMobile && <ProfilCard isMobile={isMobile} />}
    </div>
  );
}
