export const subscribedModules = [
  {
    id: 1,
    name: "Human Resource Information System",
    expiryDate: "25/11/24",
  },
  {
    id: 2,
    name: "Performance Management",
    expiryDate: "25/11/24",
  },
  {
    id: 3,
    name: "Leave Management",
    expiryDate: "25/11/24",
  },
  {
    id: 4,
    name: "Compensation & Benefits",
    expiryDate: "12/12/24",
  },
  {
    id: 5,
    name: "Request Management",
    expiryDate: "25/11/24",
  },
  {
    id: 6,
    name: "Incident Management",
    expiryDate: "25/11/24",
  },
  {
    id: 7,
    name: "Survey Management",
    expiryDate: "12/12/24",
  },
];

export const availableModules = [
  {
    id: 1,
    name: "Human Resource Information System",
    expiryDate: "25/11/24",
  },
  {
    id: 2,
    name: "Performance Management",
    expiryDate: "25/11/24",
  },
  {
    id: 3,
    name: "Leave Management",
    expiryDate: "25/11/24",
  },
  {
    id: 4,
    name: "Compensation & Benefits",
    expiryDate: "12/12/24",
  },
  {
    id: 5,
    name: "Request Management",
    expiryDate: "25/11/24",
  },
  {
    id: 6,
    name: "Incident Management",
    expiryDate: "25/11/24",
  },
  {
    id: 7,
    name: "Survey Management",
    expiryDate: "12/12/24",
  },
  {
    id: 8,
    name: "Recruitment Management",
    expiryDate: "12/12/24",
  },
  {
    id: 9,
    name: "Shift Management",
    expiryDate: "12/12/24",
  },
  {
    id: 10,
    name: "Billing Management",
    expiryDate: "12/12/24",
  },
  {
    id: 11,
    name: "Airtime Management",
    expiryDate: "12/12/24",
  },
  {
    id: 12,
    name: "Task Management",
    expiryDate: "12/12/24",
  },
];
