import { metrics2 } from "./dummydata/metricData";
import MetricCard from "./graphs/MetricCard";
import { Button, Input, Table } from "antd";
import { PlusCircleFilled, SearchOutlined } from "@ant-design/icons";
import "../../styles/superAdmin/addClient.css";
import { clientData } from "./dummydata/tableData";
import { Avatar } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  closeModal,
  makeEditModeTrue,
  makeEditModeFalse,
  makeViewModeFalse,
  makeAddModeFalse,
  makeViewModeTrue,
  makeExtendModeTrue,
  makeExtendAllModeTrue,
} from "../../redux/Admin/adminActions";
import ClientDetails from "../UI/superAdmin/clientDetails";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TableleActionColumn from "../UI/superAdmin/tableActionColumn";
import BackButton from "../UI/superAdmin/BackButton";

export default function Clients() {
  const [itemIndex, setItemIndex] = useState(null);
  const editMode = useSelector((state) => state.admin.editMode);
  const addMode = useSelector((state) => state.admin.addMode);
  const viewMode = useSelector((state) => state.admin.viewMode);

  const singleModuleMode = useSelector(
    (state) => state.admin.extendMode.perModule
  );
  const allModuleMode = useSelector(
    (state) => state.admin.extendMode.allModule
  );

  const columns = [
    { title: "#", dataIndex: "key", key: "key" },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => renderNeededComponent("name", record),
    },
    { title: "Phone Number", dataIndex: "phone", key: "phone" },
    { title: "Subscribed Modules", dataIndex: "modules", key: "modules" },
    { title: "Total Users", dataIndex: "users", key: "users" },
    {
      title: "Action",
      key: "action",
      render: (text, record) => renderNeededComponent("action", record),
    },
  ];

  const renderNeededComponent = (columnName, data) => {
    switch (columnName) {
      case "name":
        return renderNameColumn(data);
      case "action":
        return renderActionColumn(data);
      default:
        return data;
    }
  };

  const generateRandomShade = (baseColor) => {
    const hex = baseColor.replace(/[^0-9A-F]/gi, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    const factor = 0.5 + Math.random() * 0.3;

    const newR = Math.floor(r * factor);
    const newG = Math.floor(g * factor);
    const newB = Math.floor(b * factor);

    return `#${newR.toString(16).padStart(2, "0")}${newG
      .toString(16)
      .padStart(2, "0")}${newB.toString(16).padStart(2, "0")}`;
  };

  const nameColorGenerator = (name) => {
    if (["A", "B", "C", "D", "E"].includes(name)) {
      return generateRandomShade("#00A85461");
    } else if (["F", "G", "H", "I", "J"].includes(name)) {
      return generateRandomShade("#CA38384A");
    } else if (["K", "L", "M", "N", "O"].includes(name)) {
      return generateRandomShade("#F3DC8180");
    } else if (["P", "Q", "R", "S", "T"].includes(name)) {
      return generateRandomShade("#CB66D373");
    } else if (["U", "V", "W", "X", "Y", "Z"].includes(name)) {
      return generateRandomShade("#3838CA73");
    }
    return "#666666";
  };

  const renderNameColumn = (record) => (
    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Avatar
        style={{
          backgroundColor: nameColorGenerator(record.name[0]) || "#f0f0f0",
        }}
      >
        {record.name[0]}
      </Avatar>
      <div>
        <div style={{ fontWeight: "500" }}>{record.name}</div>
        <div style={{ fontSize: "12px", color: "#666" }}>{record.email}</div>
      </div>
    </div>
  );

  const handleViewDetailsClose = () => {
    setItemIndex(null);
    dispatch(closeModal(0));
    dispatch(makeEditModeFalse());
    dispatch(makeAddModeFalse());
    dispatch(makeViewModeFalse());
  };

  const handleViewDetails = (index) => {
    dispatch(makeEditModeFalse());
    dispatch(makeAddModeFalse());
    dispatch(makeViewModeTrue());
    setItemIndex(index);
    dispatch(closeModal(index));
  };

  const handleEditBtnClick = (index) => {
    setItemIndex(index);
    dispatch(makeEditModeTrue());
    dispatch(makeViewModeTrue());
    dispatch(closeModal(index));
  };

  const handleExtendSubscriptionClick = (index) => {
    dispatch(makeExtendModeTrue(index));
    dispatch(makeExtendAllModeTrue(index));
  };

  useEffect(() => {
    console.log("-----------> effect logs <--------------");
    console.log("All module mode", allModuleMode);
    console.log("single module mode", singleModuleMode);
    console.log("----------> End <--------------");
  }, [allModuleMode, singleModuleMode]);

  const navigate = useNavigate();
  const handleAddNewClientClick = () => {
    handleViewDetailsClose();
    navigate("/super_admin/add-client");
  };

  const dispatch = useDispatch();
  const isModalOpen = useSelector((state) => state.admin.modalOpen);

  const renderActionColumn = (record) => {
    return (
      <TableleActionColumn
        record={record}
        // handleModalOpen={handleModalOpen}
        handleViewDetails={handleViewDetails}
        handleEditBtnClick={handleEditBtnClick}
        handleExtendSubscriptionClick={handleExtendSubscriptionClick}
        isModalOpen={isModalOpen}
      />
    );
  };

  // ensures table is visible on initial render
  useEffect(() => {
    dispatch(makeViewModeFalse());
  }, [dispatch]);

  return (
    <section className="w-100 py-2" id="negative-margin">
      {!editMode ? (
        <div className="w-100 row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-4 justify-content-lg-start justify-content-center align-items-center">
          {metrics2.map((metric, index) => (
            <div key={index} className="ad_client_metric_card_container">
              <MetricCard {...metric} />
            </div>
          ))}
          <div className="add_newBtn ms-lg-5">
            <Button
              size="large"
              className="add-client-button d-flex align-items-center justify-content-center"
              onClick={handleAddNewClientClick}
              icon={<PlusCircleFilled className="me-2" />}
            >
              Add New Client
            </Button>
          </div>
        </div>
      ) : (
        <div className="action-header">
          <h1>{editMode ? "Update Client" : "Add Client"}</h1>
          <p>
            New clients/companies can be edited and updated manually by admin
          </p>
        </div>
      )}

      <div className="client-table-section mt-4">
        {!editMode && !addMode && !viewMode && (
          <>
            <div className="d-flex justify-content-between align-items-center mb-3 client-table-container">
              <h2 className="client-list-title">Client List</h2>
              <Input
                placeholder="Search something"
                size="large"
                suffix={<SearchOutlined style={{ color: "#a4a4a4" }} />}
                className="client-search-input"
              />
            </div>

            <div className="client-table-container">
              <Table
                columns={columns}
                dataSource={clientData}
                className="client-table"
                pagination={false}
              />
            </div>
          </>
        )}

        {viewMode && itemIndex !== null && (
          <div>
            {/* <div className="ms-md-5 ms-3">
              <Button
                size="large"
                className="back-button d-flex align-items-center justify-content-center"
                icon={<ArrowLeftOutlined className="me-2" />}
                onClick={handleViewDetailsClose}
              >
                Back
              </Button>
            </div> */}

            <BackButton backFn={handleViewDetailsClose} />
            <ClientDetails
              companyDetail={clientData[itemIndex - 1]}
              isEdit={editMode}
              addNewDetails={addMode}
            />
          </div>
        )}
      </div>
    </section>
  );
}
