import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Total Revenue",
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        autoSkip: true,
        maxRotation: 0, // might change later
        minRotation: 0, // might change later
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        callback: (value) => `${value}M`,
      },
    },
  },
};

const data = {
  labels: months,
  datasets: [
    {
      label: "2024",
      data: [1, 3, 2, 4, 3, 5, 7, 6, 4, 3, 2, 4],
      borderColor: "#2196F3",
      tension: 0.4,
    },
    {
      label: "2025",
      data: [2, 4, 3, 2, 5, 3, 6, 4, 5, 3, 4, 5],
      borderColor: "#FF6B6B",
      tension: 0.4,
    },
  ],
};

const RevenueChart = () => {
  return (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white p-4 rounded-lg shadow-sm"
      style={{ minHeight: "400px" }}
    >
      <Line options={options} data={data} />
    </div>
  );
};

export default RevenueChart;
