export const metrics = [
  {
    icon: "/assets/superAdmin/card1.svg",
    title: "Total sales",
    value: "₦ 63,560,000",
    change: "+23%",
    changeType: "increase",
  },
  {
    icon: "/assets/superAdmin/card2.svg",
    title: "Total Clients",
    value: "42",
    change: "+6%",
    changeType: "increase",
  },
  {
    icon: "/assets/superAdmin/card4.svg",
    title: "New Clients",
    value: "5",
    change: "+3%",
    changeType: "increase",
  },
  {
    icon: "/assets/superAdmin/card3.svg",
    title: "Total Modules",
    value: "14",
    change: "+2%",
    changeType: "increase",
  },
];

export const metrics2 = [
  {
    icon: "/assets/superAdmin/card1.svg",
    title: "Total sales",
    value: "₦ 63,560,000",
    change: "+23%",
    changeType: "increase",
  },
  {
    icon: "/assets/superAdmin/card2.svg",
    title: "Total Clients",
    value: "42",
    change: "+6%",
    changeType: "increase",
  },
  {
    icon: "/assets/superAdmin/card4.svg",
    title: "New Clients",
    value: "5",
    change: "+3%",
    changeType: "increase",
  },
//   {
//     icon: "/assets/superAdmin/card3.svg",
//     title: "Total Modules",
//     value: "14",
//     change: "+2%",
//     changeType: "increase",
//   },
];
