import React from "react";
import { Outlet } from "react-router-dom";
import AdminSidebar from "../UI/superAdmin/AdminSidebar";
import "../../styles/superAdmin/superAdminLayout.css";
import Header from "../UI/superAdmin/header";
import { useSelector } from "react-redux";

export default function SuperAdminLayout() {
  const isSidebarOpen = useSelector((state) => state.admin.hamburger);
  const isMobile = useSelector((state) => state.admin.mobileView);

  const shouldShowSidebar = (isMobile && isSidebarOpen) || !isMobile;

  return (
    <div className="super-admin-layout">
      {shouldShowSidebar && <AdminSidebar />}
      <div className="_super_admin_content">
        <Header />
        <div className="content-container">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
