import React from "react";
import { Input } from "antd";
import ProfilCard from "./profileCard";
import MobileHamburger from "./mobile_hamburger";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";

export default function Header() {
  const isMobile = useSelector((state) => state.admin.mobileView);

  return (
    <header className="super_admin_header container-fluid d-flex justify-content-between align-items-center py-3 position-relative">
      <div className="w-50 d-flex justify-content-start">
        <Input
          placeholder="Search"
          size="large"
          suffix={<SearchOutlined style={{ color: "#a4a4a4" }} />}
        />
      </div>

      {!isMobile && <ProfilCard isMobile={isMobile} />}

      <MobileHamburger />
    </header>
  );
}
