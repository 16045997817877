import { FaTrashAlt } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { removeModule, incrementUsers, decrementUsers } from "../../redux";
import { formatNumbers } from "../util/helpers";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useShallowEqualSelector } from "../../hooks";
import { subscription_details } from "../../selectors/userSelectors";

import styles from "../../styles/CartModule.module.css";

function CartModule({ name, badge, id, price, no_of_users, flag }) {
  const subscriptionDetails = useShallowEqualSelector(subscription_details);
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(removeModule(id));
  };

  const incrementBtnDisabled =
    no_of_users >= subscriptionDetails?.business?.no_of_users;

  const decrementBtnDisabled = no_of_users <= 1;

  const handleIncrement = () => {
    dispatch(incrementUsers(id));
  };

  const handleDecrement = () => {
    dispatch(decrementUsers(id));
  };

  return (
    <li className={styles["module-item"]}>
      <div className={styles.top}>
        <div className={styles.title}>
          <h4 className={styles.badge}>{badge}</h4>
          <h3>{name}</h3>
        </div>
        <button
          className={styles.delete}
          onClick={handleDelete}
          disabled={flag != null}
        >
          <FaTrashAlt />
        </button>
      </div>
      <div className={styles.bottom}>
        <div className={styles.price}>
          <p className="m-0 p-0">
            <span>₦{formatNumbers(price)}</span>{" "}
            <em className="text-muted"> x {no_of_users} employees</em>
          </p>
        </div>
        {/* <div className={styles.btns}>
          <button
            className="decrease"
            disabled={decrementBtnDisabled || flag}
            onClick={handleDecrement}
          >
            <MinusOutlined />
          </button>
          <button
            className="increase"
            disabled={incrementBtnDisabled || flag}
            onClick={handleIncrement}
          >
            <PlusOutlined />
          </button>
        </div> */}
      </div>
    </li>
  );
}

export default CartModule;
