import React, { useEffect } from "react";
import ClientDetails from "../UI/superAdmin/clientDetails";
import { useSelector, useDispatch } from "react-redux";
import {
  makeAddModeTrue,
  makeEditModeFalse,
  makeAddModeFalse,
  initializeEditCompanyForm,
} from "../../redux/Admin/adminActions";

export default function AddClient() {
  const newDetails = useSelector((state) => state.admin.addMode);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(makeAddModeTrue());
    dispatch(makeEditModeFalse());

    // Clear form data when mounting AddClient
    dispatch(
      initializeEditCompanyForm({
        name: "",
        email: "",
        contact: "",
        site: "",
        users: "",
        subscribedModules: [],
      })
    );

    return () => {
      dispatch(makeAddModeFalse());
      dispatch(makeEditModeFalse());
    };
  }, [dispatch]);

  return (
    <div className="w-100">
      <div className="action-header mb-n4" id="negative-margin">
        <h1>Add Client</h1>
        <p>New clients/companies can be edited and updated manually by admin</p>
      </div>

      <div className="action-header2 client-table-section mt-4">
        <ClientDetails addNewDetails={newDetails} />;
      </div>
    </div>
  );
}
