import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function PrivateRoute() {
  const isLoggedIn = useSelector((state) => state.admin.loggedIn);
  // const isLoggedIn = false;

  if (!isLoggedIn) {
    return <Navigate to="/super_admin/login" />;
  }

  return <Outlet />;
}
