import { Button } from "antd";
import { Link } from "react-router-dom";
import { SwiperSlide, Swiper } from "swiper/react";
import { Autoplay } from "swiper";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/superAdmin/login.css";
import { useRef } from "react";
import { useLoginValidator } from "../../redux/hooks/superAdminCustomHooks/hooks";
import {
  updateLoggedinState,
  updateLoginForm,
} from "../../redux/Admin/adminActions";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const slides = [
  {
    image: "/assets/superAdmin/slider_img1.svg",
    title: "Add Client",
    description: "Manage your clients efficiently",
  },
  {
    image: "/assets/superAdmin/slider_img2.svg",
    title: "Extend Subscriptions",
    description: "Streamlined administrative controls",
  },
  {
    image: "/assets/superAdmin/slider_img3.svg",
    title: "Add Module",
    description: "Streamlined administrative controls",
  },

  {
    image: "/assets/superAdmin/slider_img4.svg",
    title: "Revenue Analytics",
    description: "Streamlined administrative controls",
  },
];

export default function Login() {
  const passwordRef = useRef(null);
  const usernameRef = useRef(null);
  const dispatch = useDispatch();
  const formValues = useSelector((state) => state.admin.login);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateLoginForm(name, value));

    if (!touched[name]) {
      setTouched((prev) => ({
        ...prev,
        [name]: true,
      }));
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const [touched, setTouched] = useState({
    userName: false,
    passWord: false,
  });

  const { errorMessages, isValid } = useLoginValidator(formValues);

  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();

    setTouched({
      userName: true,
      passWord: true,
    });

    if (isValid) {
      console.log("Form submitted successfully!");
      dispatch(updateLoggedinState(true));
      navigate(`/super_admin/dashboard`);
    }
  };

  const getFieldError = (fieldName) => {
    return errorMessages.find((msg) =>
      msg.toLowerCase().includes(fieldName.toLowerCase())
    );
  };

  return (
    <div className="login">
      <div className="login-wrapper">
        {/* Left Section */}
        <div className="left-section d-lg-flex d-none">
          <Swiper
            modules={[Autoplay]}
            spaceBetween={30}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            className="login-slider"
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="slide-content">
                  <img
                    src={slide.image || "/placeholder.svg"}
                    alt={slide.title}
                    className="slide-image"
                  />
                  <h3>{slide.title}</h3>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Right Section */}
        <div className="right-section">
          <div className="login-content">
            <h1>SSL Super Admin Management</h1>
            <p>
              All Super administrative actions managed from one account to make
              management easier
            </p>

            <form className="super_loginForm" onSubmit={handleSubmit}>
              <h2>Sign In</h2>
              <div className="form-wrapper">
                <div className="input-group">
                  <label htmlFor="username">Username:</label>
                  <input
                    type="text"
                    id="username"
                    className="form-input"
                    ref={usernameRef}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="userName"
                    value={formValues.userName || ""}
                  />
                  {touched.userName && getFieldError("username") && (
                    <div className="error-message">
                      {getFieldError("username")}
                    </div>
                  )}
                </div>

                <div className="input-group">
                  <label htmlFor="password">Password:</label>
                  <input
                    type="password"
                    id="password"
                    className="form-input"
                    ref={passwordRef}
                    name="passWord"
                    value={formValues.passWord || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.passWord && getFieldError("password") && (
                    <div className="error-message">
                      {getFieldError("password")}
                    </div>
                  )}
                </div>

                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="proceed-btn"
                >
                  Proceed
                </Button>
              </div>
            </form>

            <div className="privacy-link">
              <Link to="#">Privacy</Link>
              <span className="separator">|</span>
              <Link to="#">Terms & conditions</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
