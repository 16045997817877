import "./App.css";
import { Routes, Route } from "react-router-dom";
import Register from "./components/Guest/Register";
import Modules from "./components/Guest/Modules";
import SuccessPage from "./components/Guest/SuccessPage";
import Invoice from "./components/Guest/Invoice";
import Login from "./components/Admin/Login";
import PrivateRoute from "./components/Admin/PrivateRoute";
import SuperAdminLayout from "./components/Admin/superAdminLayout";
import Dashboard from "./components/Admin/Dashboard";
import Clients from "./components/Admin/Clients";
import AddModule from "./components/Admin/AddModule";
import AddClient from "./components/Admin/AddClient";

function App() {
  return (
    <>
      <Routes>
        <Route index element={<Register />} />
        <Route path="/modules" element={<Modules />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/success" element={<SuccessPage />} />

        {/* super admin routes */}
        <Route path="/super_admin/login" element={<Login />} />

        {/* super admin protected routes */}
        <Route path="/super_admin" element={<PrivateRoute />}>
          {/* <Header /> */}
          <Route element={<SuperAdminLayout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="add-module" element={<AddModule />} />
            <Route path="clients" element={<Clients />} />
            <Route path="add-client" element={<AddClient />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
