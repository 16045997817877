import React from "react";

export default function ProfilCard(props) {
  return (
    <div
      className={`d-flex ${props.isMobile ? "mt-5 ms-3" : "mt-2"}`}
      // style={{ color: props.isMobile ? "white" : "black" }}
    >
      <div className={`me-4 d-flex my-auto flex-column`}>
        <h4
          style={{
            color: props.isMobile ? "#ffffff80" : "#464255",
          }}
        >
          Super Admin
        </h4>
        <p
          style={{
            color: props.isMobile ? "#d9d5d5" : "black",
          }}
        >
          Samantha
        </p>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        <img
          src="/assets/superAdmin/admin_img.png"
          alt="profile"
          className="rounded-3"
          width="40"
          height="40"
        />
      </div>
    </div>
  );
}
