import React from "react";

const clients = [
  { name: "NIZ", modulesSubscribed: 14, totalUsers: 46, trend: "up" },
  { name: "TGIN", modulesSubscribed: 14, totalUsers: 46, trend: "up" },
  { name: "AIT", modulesSubscribed: 14, totalUsers: 46, trend: "down" },
  { name: "TVC", modulesSubscribed: 14, totalUsers: 46, trend: "up" },
  { name: "NTIC", modulesSubscribed: 14, totalUsers: 46, trend: "down" },
];

const ClientsTable = () => {
  return (
    <div
      className="bg-white p-4 rounded-3 shadow-sm"
      style={{ minHeight: "400px" }}
    >
      <h5 className="mb-4" style={{ color: "#00000094", fontSize: "10px" }}>
        Top 5 clients by collection
      </h5>
      <div className="table-responsive">
        <table className="table">
          <thead className="bg-light">
            <tr style={{ fontSize: "10px", color: "#00000094" }}>
              <th>Clients</th>
              <th>Modules subscribed</th>
              <th>Total users</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client) => (
              <tr key={client.name}>
                <td>
                  <div className="d-flex align-items-center">
                    {client.name}
                    <img
                      src={`/assets/superAdmin/${
                        client.trend === "up"
                          ? "/Up Arrow.svg"
                          : "/Down Arrow.svg"
                      }`}
                      alt="trend"
                      className="ms-2"
                      width="16"
                      height="16"
                    />
                  </div>
                </td>
                <td className="text-center">{client.modulesSubscribed}</td>
                <td className="text-center">{client.totalUsers}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ClientsTable;
