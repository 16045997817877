const initState = {
  hamburger: false,
  mobileView: false,
  modalOpen: {},
  drawerOpen: {},
  editCompany: {
    name: "",
    email: "",
    contact: "",
    site: "",
    users: "",
    subscribedModules: [],
  },
  editMode: false,
  addMode: false,
  viewMode: false,
  extendMode: {
    singleModuleMode: {
      id: null,
    },
    allModule: {},
    perModule: {},
  },
  login: {
    userName: "",
    passWord: "",
  },
  loggedIn: false,
};

export const adminReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        hamburger: !payload.hamburger,
      };

    case "CLOSE_SIDEBAR":
      return {
        ...state,
        hamburger: false,
      };

    case "MOBILE_WIDTH":
      return {
        ...state,
        mobileView: payload.mobileView,
      };

    case "OPEN_MODAL":
      return {
        ...state,
        modalOpen: {
          ...state.modalOpen,
          [payload.index]: true,
        },
      };

    case "CLOSE_MODAL":
      return {
        ...state,
        modalOpen: {
          ...state.modalOpen,
          [payload.index]: false,
        },
      };

    case "OPEN_DRAWER":
      return {
        ...state,
        drawerOpen: {
          ...state.drawerOpen,
          [payload.index]: true,
        },
      };

    case "CLOSE_DRAWER":
      return {
        ...state,
        drawerOpen: {
          ...state.drawerOpen,
          [payload.index]: false,
        },
      };

    case "UPDATE_EDIT_COMPANY_FORM_FIELD":
      return {
        ...state,
        editCompany: {
          ...state.editCompany,
          [payload.name]: payload.value,
        },
      };

    case "INITIALIZE_EDIT_COMPANY_FORM":
      return {
        ...state,
        editCompany: {
          ...state.editCompany,
          ...payload,
        },
      };

    case "CHANGE_VIEW_MODE":
      return { ...state, viewMode: true };

    case "CLOSE_VIEW_MODE":
      return { ...state, viewMode: false };

    case "CHANGE_EDIT_MODE":
      return { ...state, editMode: true };

    case "CLOSE_EDIT_MODE":
      return { ...state, editMode: false };

    case "CHANGE_ADD_MODE":
      return { ...state, addMode: true };

    case "CLOSE_ADD_MODE":
      return { ...state, addMode: false };

    case "CHANGE_EXTEND_MODE":
      return {
        ...state,
        extendMode: {
          ...state.extendMode,
          [payload.index]: true,
        },
      };

    case "CLOSE_EXTEND_MODE":
      return {
        ...state,
        extendMode: {
          ...state.extendMode,
          [payload.index]: false,
        },
      };

    case "CHANGE_EXTEND_MODE_PER_MODULE":
      return {
        ...state,
        extendMode: {
          ...state.extendMode,
          perModule: {
            ...state.extendMode.perModule,
            [payload.index]: true,
          },
        },
      };

    case "CLOSE_EXTEND_MODE_PER_MODULE":
      return {
        ...state,
        extendMode: {
          ...state.extendMode,
          perModule: {
            ...state.extendMode.perModule,
            [payload.index]: false,
          },
        },
      };

    case "CHANGE_EXTEND_MODE_ALL_MODULE":
      return {
        ...state,
        extendMode: {
          ...state.extendMode,
          allModule: {
            ...state.extendMode.allModule,
            [payload.index]: true,
          },
        },
      };

    case "CLOSE_EXTEND_MODE_ALL_MODULE":
      return {
        ...state,
        extendMode: {
          ...state.extendMode,
          allModule: {
            ...state.extendMode.allModule,
            [payload.index]: false,
          },
        },
      };

    case "CHANGE_EXTEND_MODE_PER_MODULE_ID":
      return {
        ...state,
        extendMode: {
          ...state.extendMode,
          singleModuleMode: {
            ...state.extendMode.singleModuleMode,
            id: payload.id,
          },
        },
      };

    case "CLOSE_EXTEND_MODE_PER_MODULE_ID":
      return {
        ...state,
        extendMode: {
          ...state.extendMode,
          singleModuleMode: {
            ...state.extendMode.singleModuleMode,
            id: null,
          },
        },
      };

    case "UPDATE_LOGIN_FORM": {
      return {
        ...state,
        login: {
          ...state.login,
          [payload.name]: payload.value,
        },
      };
    }

    case "UPDATE_LOGGEDIN_STATE": {
      return {
        ...state,
        loggedIn: payload.loggedIn,
      };
    }

    default:
      return state;
  }
};
