export const toggleAdminNav = (hamburger) => {
  return {
    type: "TOGGLE_SIDEBAR",
    payload: { hamburger },
  };
};

export const checkMobileView = (isMobile) => {
  return {
    type: "MOBILE_WIDTH",
    payload: { mobileView: isMobile },
  };
};

export const openModal = (index) => {
  return { type: "OPEN_MODAL", payload: { index } };
};

export const closeModal = (index) => {
  return { type: "CLOSE_MODAL", payload: { index } };
};

export const openDrawer = (index) => {
  return { type: "OPEN_DRAWER", payload: { index } };
};

export const closeDrawer = (index) => {
  return { type: "CLOSE_DRAWER", payload: { index } };
};

export const updateCompanyDetails = (name, value) => ({
  type: "UPDATE_EDIT_COMPANY_FORM_FIELD",
  payload: { name, value },
});

export const initializeEditCompanyForm = (companyDetail) => ({
  type: "INITIALIZE_EDIT_COMPANY_FORM",
  payload: companyDetail,
});

export const makeEditModeTrue = () => {
  return {
    type: "CHANGE_EDIT_MODE",
    payload: true,
  };
};

export const makeViewModeTrue = () => {
  return {
    type: "CHANGE_VIEW_MODE",
    payload: true,
  };
};
export const makeViewModeFalse = () => {
  return {
    type: "CLOSE_VIEW_MODE",
    payload: false,
  };
};
export const makeEditModeFalse = () => {
  return {
    type: "CLOSE_EDIT_MODE",
    payload: false,
  };
};

export const makeAddModeTrue = () => {
  return {
    type: "CHANGE_ADD_MODE",
    payload: true,
  };
};

export const makeAddModeFalse = () => {
  return {
    type: "CLOSE_ADD_MODE",
    payload: false,
  };
};

export const makeExtendModeTrue = (index) => {
  return {
    type: "CHANGE_EXTEND_MODE",
    payload: { index },
  };
};

export const makeExtendModeFalse = (index) => {
  return {
    type: "CLOSE_EXTEND_MODE",
    payload: { index },
  };
};

export const makeExtendPerModuleTrue = (index) => {
  return {
    type: "CHANGE_EXTEND_MODE_PER_MODULE",
    payload: { index },
  };
};

export const makeExtendPerModuleFalse = (index) => {
  return {
    type: "CLOSE_EXTEND_MODE_PER_MODULE",
    payload: { index },
  };
};

export const makeExtendAllModeTrue = (index) => {
  return {
    type: "CHANGE_EXTEND_MODE_ALL_MODULE",
    payload: { index },
  };
};

export const makeExtendAllModeFalse = (index) => {
  return {
    type: "CLOSE_EXTEND_MODE_ALL_MODULE",
    payload: { index },
  };
};

export const makeExtendPerModuleIDTrue = (index, id) => {
  return {
    type: "CHANGE_EXTEND_MODE_PER_MODULE_ID",
    payload: { index, id },
  };
};

export const makeExtendPerModuleIDFalse = (index) => {
  return {
    type: "CLOSE_EXTEND_MODE_PER_MODULE_ID",
    payload: { index },
  };
};

export const updateLoginForm = (name, value) => {
  return {
    type: "UPDATE_LOGIN_FORM",
    payload: { name, value },
  };
};

export const updateLoggedinState = (loggedIn) => {
  return {
    type: "UPDATE_LOGGEDIN_STATE",
    payload: { loggedIn },
  };
};
