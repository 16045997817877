function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomPhoneNumber() {
  const phonePrefixes = ["+234", "+1", "+44", "+33"];
  const prefix =
    phonePrefixes[Math.floor(Math.random() * phonePrefixes.length)];
  const phoneNumber = getRandomInt(100000000, 999999999);
  return `${prefix} ${phoneNumber}`;
}

function getRandomName() {
  const names = [
    "NIZ",
    "Alvares Int.",
    "Aura Corp.",
    "Tech Solutions",
    "DataVision",
    "Innova Systems",
  ];
  return names[Math.floor(Math.random() * names.length)];
}

function getRandomEmail(name) {
  const domains = ["gmail.com", "yahoo.com", "outlook.com", "company.com"];
  return `${name.toLowerCase()}@${
    domains[Math.floor(Math.random() * domains.length)]
  }`;
}

function getRandomSite() {
  const sites = [
    "Aura.apps.sslinsight",
    "Alvaraz.apps.sslinsight",
    "DataVision.apps.sslinsight",
    "TechSolutions.apps.sslinsight",
  ];
  return sites[Math.floor(Math.random() * sites.length)];
}

export const clientData = Array.from({ length: 15 }, (_, index) => ({
  key: (index + 1).toString(),
  name: getRandomName(),
  email: getRandomEmail(getRandomName()),
  phone: getRandomPhoneNumber(),
  site: getRandomSite(),
  subscribedModules: getRandomInt(1, 10),
  users: getRandomInt(20, 50),
}));
